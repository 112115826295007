import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


import { Grid, Box } from '@mui/material';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import TopNavigationBar from '../utilities/TopNavigationBar';
import { useSelector, useDispatch, connect } from 'react-redux'

import { getAreaName } from '../utilities/StringHelper';
import { getSelectedOrg } from '../firebase/auth';
import { getVenuesWithAreas} from '../actions/venuesActions'


import DailyWorkLogsOnVenue from './DailyWorkLogsOnVenue';
import WeeklyWorkLogsOnVenue from './WeeklyWorkLogsOnVenue';
import MonthlyWorkLogsOnVenue from './MonthlyWorkLogsOnVenue';
import ReportWorkLogsOnVenue from './ReportWorkLogsOnVenue';
import TimesheetRecordsOnVenue from './TimesheetRecordsOnVenue';

import moment from 'moment';

var options = [ 'DAILY', 'MONTHLY','REPORT','TIMESHEET'];
// var options = [ 'DAILY', 'WEEKLY', 'MONTHLY','REPORT' ];


const getTranslation = (key) => {
	if (key == 'MONTHLY') {
		return 'Havi';
	} else if (key == 'DAILY') {
		return 'Napi';
	} else if (key == 'WEEKLY') {
		return 'Heti';
	}else if (key == 'REPORT') {
		return 'Riport';
	}else if (key == 'TIMESHEET') {
		return 'Jelenléti ív';
	}
	return '';
};

function WorkLogsOnVenue({venues}) {
	const [ alignment, setAlignment ] = React.useState('DAILY');
	const queryParams = new URLSearchParams(window.location.search);
	const venueId = queryParams.get('id')
	const [ venue, setVenue ] = React.useState({});
	// const [ venue, setVenue ] = useSelector((state) => state && state.venues && state.venues[venueId] ? state.venues[venueId] : {} )
	const dispatch = useDispatch()

	const handleChange = (event, newAlignment) => {
		setAlignment(newAlignment);
	};

	// useEffect(() => {
	// 	if(venue == null){
	// 		dispatch(getVenuesWithAreas(getSelectedOrg()))
	// 	}
	// }, []);


	return (
		venues && Object.keys(venues).length > 0 ?
		<Grid container direction="column">
			<Grid item>
				<TopNavigationBar title={venues[venueId].name} />
			</Grid>
			<Grid item>
				<Box m={2}>
					<ToggleButtonGroup size="small" color="primary" value={alignment} exclusive onChange={handleChange}>
						{options.map((element) => (
							<ToggleButton value={element}>{getTranslation(element)}</ToggleButton>
						))}
					</ToggleButtonGroup>
				</Box>
			</Grid>
			<Grid item>{alignment == 'DAILY' && <DailyWorkLogsOnVenue venue={venues[venueId]} venueId={venueId} />}</Grid>
			{/* <Grid item>{alignment == 'WEEKLY' && <WeeklyWorkLogsOnVenue venue={venues[venueId]} venueId={venueId}/>}</Grid> */}
			<Grid item>{alignment == 'MONTHLY' && <MonthlyWorkLogsOnVenue venue={venues[venueId]} venueId={venueId}/>}</Grid>
			<Grid item>{alignment == 'REPORT' && <ReportWorkLogsOnVenue venue={venues[venueId]} venueId={venueId}/>}</Grid>
			<Grid item>{alignment == 'TIMESHEET' && <TimesheetRecordsOnVenue venue={venues[venueId]} venueId={venueId}/>}</Grid>


		</Grid> :
		<div/>
	);
}
const mapStateToProps = function(state) {
	return {
	  venues: state.venues
	}
  }
export default connect(mapStateToProps)(WorkLogsOnVenue)
